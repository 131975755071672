<template>
  <div class="trasaction-price">
    {{ price }} {{$currency}}
  </div>
</template>
<script>

export default {
  props: ['price'],
}
</script>
<style scoped>
.trasaction-price {
  letter-spacing: 0px;
  color: #00D1B7;
  opacity: 1;
}
</style>
